.field {
    margin-bottom: 10px;
  }
  .subtitle{
    color: coral;
    font-weight: 400;
   }
  .field label {
    display: block;
    font-size: 12px;
    color: #777;
  }
  
  .field input {
    display: block;
    line-height: 1.5;
  }
  
  input[type="submit"] {
    font-weight: 400;
    font-size: 28px;
    font-family:Arial, Helvetica, sans-serif;
    border-color: aquamarine;
    color: white;
    border-style: solid;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: default;
    box-sizing: border-box;
    background-color: black;
    margin: 0em;
    padding: 10px;
    border-width: 3px;
    border-radius: 10px;
    margin-right: 10px;
    margin-top: 10px;
    width: auto;
    margin-top: 20px;
  }

  input{
    background: none;
    color: white;
    font-size: calc(20px + 2vmin);
    border-bottom: solid 3px;
    border-top: none;
    border-right: none;
    border-left: none;
    width: 99%;
  }

  input:focus-visible{
    outline-color:transparent;
    outline-width: 0px;
  }
  input:focus{
    background-color: rgb(0, 14, 23);
  }
  input:hover{
    background-color: rgb(75, 75, 75);
   }


  form{
    width: 100%;
  }