body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

div{
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.button2{
  border: solid 2px coral !important;
  color: white !important;
  background-color: black;
  text-transform: none !important;
}
.button2:hover{
  background-color: coral;
  color: white;
}
.button2:active{
 background-color: rgb(249, 85, 26);
}


.button3{
  border: solid 2px darkseagreen !important;
  color: white !important;
  background-color: black;
  text-transform:none !important;
}
.button3:hover{
  background-color: darkseagreen;
  color: white;
}
.button3:active{
  background-color: rgb(74, 108, 74);
}

.button4{
  border: solid 2px pink !important;
  color: white !important;
  background-color: black;
  text-transform: none !important;
}
.button4:hover{
  background-color: pink;
  color: white;
}
.button4:active{
  background-color: rgb(243, 116, 137);
}