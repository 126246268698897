@import "~react-image-gallery/styles/css/image-gallery.css";

.Button-header{
    color: aquamarine;
    background-color: black;
    font-size: calc(10px + 2vmin);
    border: none;
    padding-left: 5px;
    text-decoration: underline;
}

.Button-header:hover{
    font-style: oblique;
}

img{
    width: 100%;
}

.position{
    position: fixed;
    bottom: 0px;
    left: 0px;
    background-color: black;
    height: 60px;
    width: 100%;
    padding-top: 10px;
}

.button-list{
    padding-left: 16px;

}


@media only screen and (max-width: 600px) {
    .carousel{
        width: 100%;
    }
    .listProducts{
        width: 100%;
    }
    .Container{
        display: flex;
        flex: auto;
        flex-direction: column;
    }
    .imageClass{
        width: 100%;
    }
    .MenuProduct{
       display: none;
    }
    .positionMobile{
        position: fixed;
        bottom: 0px;
        left: 0px;
        background-color: black;
        height: 60px;
        width: 100%;
        padding-top: 10px;
    }
    .positionWeb{
        display: none;
    }
    
}

@media only screen and (min-width: 600px) {
    .carousel{
        width: 40%;
        min-height:100%;
        display: table-cell;
        overflow-y:hidden;
        overflow-x:hidden;
    }
    .listProducts{
        width: 60%;
        padding-left: 40px;
    }
    .Container{
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
    }
    .imageClass{
        width: 40%;
        position: fixed;
    }
    .MenuProduct{
        position: sticky;
        top: 50px;
        z-index: 1;
        background-color: black;
        padding-bottom: 10px;
        border-bottom: 1px solid white;
    }
    .positionWeb{
        position: fixed;
        bottom: 0px;
        left: 0px;
        background-color: black;
        height: 60px;
        width: 100%;
        padding-top: 10px;
    }
    .positionMobile{
        display: none;
    }

}
 .textButton{
     margin: 0;
     text-align: left;
     font-weight: 400 !important;
     font-family: Arial, Helvetica, sans-serif;
     color: white;
     font-size: calc(10px + 2vmin);
     display: flex;
     flex-direction: column;
 }

.textButton{
    border: 0;
    background: none;
 }
.textButton:hover{
    color: coral;
}

.textButton:focus{
    color: aquamarine;
    font-style: oblique;
}
.textButton:active{
    color:coral !important;
}



.descriptionClass{
    display: flex; 
    padding-top:10px; 
    margin-top:10px;
    width: 100%;
    background-color: black;
    font-weight: 200;
    font-size: calc(8px + 2vmin);
    margin-bottom: 80px;
    
}

.div-content{
    display: grid;
    
}
.div-title{
    position: fixed;
    height: auto;
}

.Modal{
    position: absolute;
    transform: translate(-50%, -50%);
    width: 100%;
    background-color: inherit;
    opacity: 75%;
    left: 50%;
    height: 100%;
    top: 50%;
    backdrop-filter: blur(8px);
}
.ModalProduct{
    padding-left: 20px;
    padding-bottom: 5px;
    border-bottom: 1px solid white;
    margin-bottom: 5px;
}
.ModalProduct:first-child{
    padding-top: 20px;
}

.MenuButton{
    border: solid 2px darkseagreen !important;
    color: white !important;
    background-color: black;
    text-transform:none !important;
}
.MenuButton:hover{
    background-color: darkseagreen;
    color: white;
  }
  .MenuButton:active{
    background-color: rgb(74, 108, 74);
  }

.OtherModalButton{
    display:flex;
    flex-direction:column;
    bottom: 0;
    margin-bottom: 20px;
    position: fixed;
    align-items: flex-end;
    margin-left: -20px;
}
.OtherTopModalButton{
    display:flex;
    flex-direction:column;
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: flex-end;
    margin-left: -20px;
}
