.App {
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #000000;
  min-height: 3vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: aquamarine;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  font-weight: 400;

  justify-content: flex-start;
  top: 0px;
  left: 0px;
  position: fixed;
  z-index: 100;
  width: 100%;
}

.Subtitle{
 color: coral;
 font-weight: 400;
}

body{
  background-color: #000000;

  align-items: flex-start;
  justify-content: center;
  display: flex;
  flex-direction: column;
 
  text-align: left;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  font-size: calc(36px + 2vmin);
  
  padding-top: 20px;
  padding-bottom: 10px;
  /* padding-left: 20px;
  padding-right: 20px; */
  height: 100%;
}

.clase-a{
  font-size: calc(36px + 2vmin) !important;
  width: 100%;
}

.Button{
  font-weight: 400;

  font-size: 28px;
  font-family:Arial, Helvetica, sans-serif;

  color: white;
  border-style: solid;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  box-sizing: border-box;
  background-color: black;
  margin: 0em;
  padding: 10px;
  border-width: 3px;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

.buttonGroup{
  padding-top: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.red{
  border-color: red;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.ButtonCoral{
  font-weight: 400;

  font-size: 28px;
  font-family:Arial, Helvetica, sans-serif;
  border-color: coral;
  color: white;
  border-style: solid;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  box-sizing: border-box;
  background-color: black;
  margin: 0em;
  padding: 10px;
  border-width: 3px;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 10px;
}
.ButtonCoral:hover{
  background-color: coral;
  color: white;
}
.ButtonCoral:active{
  background-color: rgb(249, 85, 26);
  border-color: rgb(249, 85, 26);
}

.ButtonDarkseagreen{
  font-weight: 400;

  font-size: 28px;
  font-family:Arial, Helvetica, sans-serif;
  border-color: darkseagreen;
  color: white;
  border-style: solid;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  box-sizing: border-box;
  background-color: black;
  margin: 0em;
  padding: 10px;
  border-width: 3px;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 10px;
}
.ButtonDarkseagreen:hover{
  background-color: darkseagreen;
  color: white;
}
.ButtonDarkseagreen:active{
  background-color: rgb(74, 108, 74);
}

.ButtonPink{
  font-weight: 400;

  font-size: 28px;
  font-family:Arial, Helvetica, sans-serif;
  border-color: pink;
  color: white;
  border-style: solid;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  box-sizing: border-box;
  background-color: black;
  margin: 0em;
  padding: 10px;
  border-width: 3px;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 10px;
}
.ButtonPink:hover{
  background-color: pink;
  color: white;
}
.ButtonPink:active{
  background-color: rgb(243, 116, 137);
}